export const colors = {
  textPrimary: 'rgb(5, 71, 82)',
  textSecondary: 'rgb(80,80,80)',
  blue: '#0298da',
  red: 'rgb(218 2 2)',
  lightgray: 'rgb(230,230,230)',
  superlightgray: 'rgb(240,240,240)',
  white: '#fff',
  black: 'rgb(5, 71, 82)',
  brand: '#4285F4',
  success: '#3bc439',
  warn: '#ffb254',
  invalid: '#ababab',
  border: '#e3e3e3',
  danger: '#d1463d'
}

export function alpha(color: string, alphaNumber: number | string) {
  if (typeof alphaNumber === 'string' && alphaNumber.length === 2) {
    return color + alphaNumber
  } else if (typeof alphaNumber === 'number') {
    const alphaHexNum = parseInt((alphaNumber * 255).toString());
    return color + alphaHexNum.toString(16);
  }
  return color;
}