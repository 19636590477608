import React from 'react';
import iosImage from 'assets/download_ios.png';
import androidImage from 'assets/download_android.png';

const imageLinks = {
    ios: iosImage, //'https://linkmaker.itunes.apple.com/images/badges/en-us/badge_appstore-lrg.svg',
    android: androidImage//'https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png',
};

interface Props {
    store: 'ios' | 'android';
    url: string;
    height?: number;
    width?: number;
    linkStyles?: any;
    linkProps?: any;
    className?: string;
}

const MobileStoreButton: React.FC<Props> = ({
    store,
    url,
    height = 75,
    width = 255,
    linkStyles,
    linkProps,
    className
}) => {

    const defaultLinkStyles = {
        background: `url(${imageLinks[store]}) no-repeat`,
        backgroundSize: 'contain',
        display: 'inline-block',
        overflow: 'hidden',
        textDecoration: 'none',
        height: '100%',
        width: '100%',
        padding: '5px',
        ...linkStyles,
    };

    return (
        <div className={className} style={{ height, width, display: 'inline-block' }}>
            <a
                style={defaultLinkStyles}
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                {...linkProps}
            >
                &nbsp;
            </a>
        </div>
    );
}

export default MobileStoreButton;
