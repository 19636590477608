import { Body, ExtraLargeTitle, LargeTitle } from 'components/Typography';
import React from 'react';
import styled from 'styled-components';
import { FiCalendar, FiZap } from 'react-icons/fi';
import { HiOutlineShieldCheck } from 'react-icons/hi';
import { TbLeaf } from 'react-icons/tb';
import { MdAttachMoney } from 'react-icons/md';
import { AiOutlineCar } from 'react-icons/ai';
import { colors } from 'utils/colors';

const Benefits: React.FC = () => {

    return (
        <Container>
            <ExtraLargeTitle>Beneficios</ExtraLargeTitle>
            <BenefitsContainer>
                <Benefit IconComp={MdAttachMoney} color={'#00C6B5'} title={'Más barato'} description="Compartí los costos del viaje entre todos" />
                <Benefit IconComp={FiZap} color={'#E39D22'} title={'Rápido y simple'} description="Pedí que te busquen y te dejen donde quieras" />
                <Benefit IconComp={HiOutlineShieldCheck} color={'#4285F4'} title={'Seguro'} description="Leé los comentarios y las estrellas de tu conductor" />
                <Benefit IconComp={AiOutlineCar} color={'#4285F4'} title={'Cómodo'} description="Ya no tendrás problema con quienes te reclinan el asiento" />
                <Benefit IconComp={TbLeaf} color={'#00C6B5'} title={'Sustentable'} description="Contribuí al cuidado ambiental reduciendo emisiones de carbono" />
                <Benefit IconComp={FiCalendar} color={'#E39D22'} title={'Horarios flexibles'} description="Buscá el dia y horario que más te convenga" />
            </BenefitsContainer>
        </Container>

    )

}

export default Benefits;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;


const BenefitsContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
`;


interface BenefitProps {
    title: string;
    IconComp: any;
    description: string;
    color?: string;
}

const Benefit: React.FC<BenefitProps> = ({ color, title, IconComp, description }) => {

    const size = 36;
    const _color = color ?? colors.black;

    return (
        <BenefitContainer>
            <IconComp size={size} color={_color} />
            <LargeTitle style={{ textAlign: 'center' }}>{title}</LargeTitle>
            <Body style={{ textAlign: 'center' }}>{description}</Body>
        </BenefitContainer>
    )
}

const BenefitContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
    max-width: 250px;
    padding: 16px;
    margin: 8px;
`







