import React, { useEffect, useRef, useState } from 'react';
import { Slide, SlideProps } from 'react-awesome-reveal';
import styled from 'styled-components';
import { Body, ExtraLargeTitle, LargeTitle, SuperExtraLargeTitle } from '../../../components/Typography';
import { colors } from '../../../utils/colors';
import MobileStoreButton from 'components/MobileStoreButton';
import appPreview from 'assets/app_preview.png';
import appPreview2 from 'assets/app_preview_2.png';
import appPreview3 from 'assets/app_preview_3.png';
import appPreview4 from 'assets/app_preview_4.png';
import appPreview5 from 'assets/app_preview_5.png';
import appPreview6 from 'assets/app_preview_6.png';
import asPassengerAsset from 'assets/passenger2.png';
import asDriverAsset from 'assets/driver2.png';
import useIsMobile from 'hooks/useIsMobile';
import brand from 'assets/vamosjuntoslogo.png';
import movingCarGif3 from 'assets/camionetaMoviendose3.gif';
import Footer from './Footer';
import Benefits from './Benefits';

export const downloadAppSectionId = 'download-app';

interface DownloadAppProps { }

const DownloadApp: React.FC<DownloadAppProps> = ({ }) => {

  const isMobile = useIsMobile();

  const appPreviewSrc = useAppPreviewSrc();

  const storeButtonWidth = 200;
  const storeButtonRatioHeight = (w: number) => w * 40 / 136;
  const storeButtonHeight = storeButtonRatioHeight(storeButtonWidth);

  const rowContent = (
    <>
      <Content>
        <PreventSlideOnMobile>
          <LogoContainer>
            <Logo src={brand} />
          </LogoContainer>
          <AtentionContainer>
            <SuperExtraLargeTitle>
              Compartí tus viajes, ahorrá tiempo y dinero
            </SuperExtraLargeTitle>
          </AtentionContainer>
          <DownloadButtonsContainer>
            <MarginedStoreButton
              width={storeButtonWidth}
              height={storeButtonHeight}
              store={'ios'}
              url={"https://apps.apple.com/uy/app/vamos-juntos/id1574091593?l=es"}
            />
            <MarginedStoreButton
              width={storeButtonWidth}
              height={storeButtonHeight}
              store={'android'}
              url={"https://play.google.com/store/apps/details?id=uy.vamosjuntos"}
            />
          </DownloadButtonsContainer>

        </PreventSlideOnMobile>
      </Content>

      <IphoneContent>
        <PreventSlideOnMobile direction="right" style={{ height: '100%' }}>
          <Iphone src={appPreviewSrc} />
        </PreventSlideOnMobile>
      </IphoneContent>
    </>
  )

  return (
    <PageContainer>
      <ColumnContainer>
        {isMobile ? rowContent : <Container>
          {rowContent}
        </Container>
        }

        <PreventSlideOnMobile>
          <MarginedContainer reduced={!isMobile}>
            <ExtraLargeTitle>Ya seas conductor o pasajero, </ExtraLargeTitle>
            <StyledBody style={{ marginTop: 12, marginBottom: 42 }}>
              si queres viajar a otro departamento y los omnibus son caros y lentos,
              o si tenés auto y viajas solo ¿porqué no compartís los gastos?
              <strong> ¿Vamos Juntos?</strong> te facilita unirte y planificar viajes con terceros.
            </StyledBody>
            <Flex style={{ flexDirection: isMobile ? 'column' : 'row', marginBottom: '32px', alignItems: 'center' }}>
              <FlexItem style={{ flexGrow: 1 }}>
                <LargeTitle>Como <strong>pasajero</strong> podrás:</LargeTitle>
                <StyledBody style={{ marginTop: 12, marginBottom: 42 }}>
                  &bull; buscar viajes y conectar con personas para encontrar el viaje que mejor se adapte a tus necesidades<br />
                  &bull; saber de antemano cuanto te van a cobrar por el viaje<br />
                  &bull; coordinar por chat detalles del viaje<br />
                  &bull; calificar a los conductores<br />
                </StyledBody>
              </FlexItem>
              {/* <HideOnMobile> */}
              <FlexItem style={{ marginLeft: 32 }}>
                <img src={asPassengerAsset} style={{ width: 180 }} />
              </FlexItem>
              {/* </HideOnMobile> */}
            </Flex>
            <Flex style={{ flexDirection: isMobile ? 'column' : 'row', alignItems: 'center' }}>
              <FlexItem style={{ flexGrow: 1 }}>
                <LargeTitle>Como <strong>conductor</strong> podrás:</LargeTitle>
                <StyledBody style={{ marginTop: 12, marginBottom: 42 }}>
                  &bull; crear viajes y conectar con pasajeros<br />
                  &bull; elegir a los pasajeros que mejor se adapten a tu recorrido<br />
                  &bull; ver de antemano a donde van tus pasajeros antes de aceptarlos<br />
                  &bull; definir el precio del viaje<br />
                </StyledBody>
              </FlexItem>
              {/* <HideOnMobile> */}
              <FlexItem style={{ marginLeft: 32 }}>
                <img src={asDriverAsset} style={{ width: 180 }} />
              </FlexItem>
              {/* </HideOnMobile> */}
            </Flex>
          </MarginedContainer>
        </PreventSlideOnMobile>
        <PreventSlideOnMobile>
          <MarginedContainer reduced={!isMobile}>
            <Benefits />
          </MarginedContainer>
        </PreventSlideOnMobile>
        {/* <PuenteDeLaBarra /> */}

        <Footer />
        <img style={{ width: '100%' }} src={movingCarGif3} />
      </ColumnContainer>
      {/* {!isMobile && <Background><img style={{ width: '100%' }} src={backgroundImg} /></Background >} */}
    </PageContainer>
  )
}

export default DownloadApp


const Flex = styled.div`
flex-direction: row;
display: flex;
`

const FlexItem = styled.div``

const ColumnContainer = styled.div`
      flex-direction: column;
      display: flex;
      overflow-y: scroll;
      height: 100%;
      `;

const LogoContainer = styled.div`
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      `

const Logo = styled.img`
      max-width: 200px;
      width: 100%;
      align-self: center;
      `

const MarginedStoreButton = styled(MobileStoreButton)`
      margin: 0px 8px;
      `

const AtentionContainer = styled.div`
      text-align: center;
      `

const MarginedContainer = styled.div<{ reduced?: boolean }>`
      margin: ${({ reduced }) => reduced ? '48px 20%' : '48px 16px'};
      `

const Container = styled.div`
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      height: 90%;
      `

const IphoneContent = styled.div`
      padding: 0px 64px;
      height: 100%;
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items:center;
      justify-content: center;
      margin-top: 16px;
      `

const Content = styled.div`
      flex: 1;
      padding: 0px 16px;
      `;

const Iphone = styled.img`
      border-radius: 8px;
      display: block;
      max-height: 90%;
      width: auto;
      height: auto;
      -webkit-filter: drop-shadow(5px 5px 5px #222);
      filter: drop-shadow(5px 5px 5px #222);
      `

const StyledBody = styled(Body)`
      color: ${colors.textSecondary};
      margin-top: 8px;
      `

const DownloadButtonsContainer = styled.div`
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-top: 16px;
      `

const PageContainer = styled.div`
      position:absolute;
      width: 100%;
      height: 100%;
      z-index: -2;
      overflow-y: scroll;
      background: rgb(210,232,253);
      padding-top: 16px;
      `


const PreventSlideOnMobile: React.FC<SlideProps> = ({ children, ...slideProps }) => {
  const isMobile = useIsMobile();
  if (isMobile) {
    return <>{children}</>
  }

  return (
    <Slide {...slideProps}>
      {children}
    </Slide>
  )
}

const images = [appPreview, appPreview2, appPreview3, appPreview4, appPreview5, appPreview6]
function useAppPreviewSrc() {
  const [index, setIndex] = useState(0);
  const interval = useRef(0);
  useEffect(() => {
    interval.current = setInterval(() => {
      setIndex(i => (i + 1) % images.length);
    }, 3000) as unknown as number;

    return () => clearInterval(interval.current);
  }, []);

  return images[index];

}

