import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import DownloadApp from 'routes/Home/sections/DownloadApp';
import TermsAndConditions from 'routes/Terms/TermsAndConditions';
// import UnderConstruction from './routes/UnderConstruction/UnderConstruction';

function App() {
  return (
    <Router>
      <Switch>
        <Route path={["/terms", "/terminos", "/terminos-y-condiciones"]}>
          <TermsAndConditions />
        </Route>
        <Route path="/">
          <DownloadApp />
        </Route>
        <Route path="/terminos-y-condiciones">
          <TermsAndConditions />
        </Route>
        {/* <Route path="/">
          <UnderConstruction />
        </Route> */}
      </Switch>
    </Router>

  );
}

export default App;
