import styled from "styled-components"

const Footer: React.FC = () => {

    return (
        <Container>

        </Container>
    )


}

export default Footer;

const Container = styled.div`
  
`
