import { Body as UnPaddedBody, ExtraLargeTitle, LargeTitle as UnPaddedLargeTitle, SuperExtraLargeTitle } from 'components/Typography'
import useIsMobile from 'hooks/useIsMobile';
import React from 'react'
import styled from 'styled-components';



const TermsAndConditions: React.FC = () => {
    const isMobile = useIsMobile();

    return (
        <Container style={{ padding: isMobile ? '16px 24px 24px 8px' : '16px 64px' }}>
            <SuperExtraLargeTitle style={{ marginBottom: 24 }}>Condiciones generales de uso</SuperExtraLargeTitle>
            <PaddedContent>
                <ExtraLargeTitle>1. Descripción</ExtraLargeTitle>
                <PaddedContent>
                    <Body>
                        ¿Vamos juntos? (de aquí en adelante denominada “¿Vamos juntos?”) ha desarrollado una plataforma (aplicación movil) de trayectos compartidos a la que se puede acceder descargandola para iOS o Android desde el sitio web www.vamosjuntos.uy, diseñada para poner en contacto a los conductores que viajan a un determinado destino con pasajeros que se dirigen al mismo lugar, de forma que puedan compartir el Trayecto así como los costes asociados al mismo.
                        Las presentes condiciones generales de uso tienen por objeto regular el acceso y los términos de uso de la Plataforma. Se ruega leer atentamente estas condiciones. Entiendes y reconoces que ¿Vamos juntos? no es parte en ningún contrato, acuerdo o relación contractual, de ninguna clase, suscrito entre los Usuarios de la Plataforma y tú.
                        Al hacer clic en “Iniciar sesión con Google”, “Iniciar sesión con Apple o “Registrarse con una dirección de correo electrónico”, reconoces que has leído y aceptado las presentes condiciones generales de uso.
                    </Body>
                </PaddedContent>
                <ExtraLargeTitle>2. Definiciones</ExtraLargeTitle>
                <PaddedContent>
                    <Body>
                        En este documento,
                        <Body><strong>“Anuncio”</strong> significa un anuncio relacionado con un Trayecto publicado por un usuario Conductor en la Plataforma. </Body>
                        <Body><strong>“Anuncio de coche compartido”</strong> significa un anuncio relativo a un Desplazamiento publicado en la Plataforma por un Conductor. </Body>
                        <Body><strong>“¿Vamos Juntos?”</strong> tiene el significado que se le atribuye en el Artículo 1 arriba. </Body>
                        <Body><strong>“CGU”</strong> significa las presentes Condiciones Generales de Uso. </Body>
                        <Body><strong>“Cuenta”</strong> significa la cuenta que debe crearse para ser Usuario y poder acceder a determinados servicios ofrecidos por la Plataforma. </Body>
                        <Body><strong>“Cuenta de Google”</strong> tiene el significado que se le atribuye en el Artículo 3.2 a continuación. </Body>
                        <Body><strong>“Conductor”</strong> significa el Usuario que utiliza la aplicacion para ofrecer la compartición de un trayecto a otra persona a cambio de compartir los costes asociados al trayecto, y transportar a dicha persona en un trayecto determinado en la fecha y hora en las que el conductor ha organizado el trayecto con anterioridad. </Body>
                        <Body><strong>“Confirmación de la reserva”</strong> tiene el significado que se le atribuye en el Artículo 4.2.1 a continuación. </Body>
                        <Body><strong>“Contenido desarrollado por el Usuario”</strong> tiene el significado que se le atribuye en el Artículo 11.2 a continuación. </Body>
                        <Body><strong>“Gastos compartidos”</strong> significa, para un Trayecto de coche compartido determinado, la suma de dinero solicitada por el Conductor y aceptada por el Pasajero por su contribución a los gastos del viaje. </Body>
                        <Body><strong>“Gastos de Gestión”</strong> tiene el significado que se le atribuye en el Artículo 5.2 a continuación. </Body>
                        <Body><strong>“Pasajero”</strong> significa el Usuario que ha aceptado compartir un trayecto con el conductor en carácter de acompañante. </Body>
                        <Body><strong>“Plataforma”</strong> tiene el significado que se le atribuye en el Artículo 1 arriba. </Body>
                        <Body><strong>“Puntuación”</strong> se refiere al puntaje asociado a un Usuario generado por opiniones y valoraciones de otros Usuarios</Body>
                        <Body><strong>“Plaza”</strong> se refiere a un asiento en un coche compartido. </Body>
                        <Body><strong>“Servicios”</strong> significa todos los servicios ofrecidos por ¿Vamos juntos? por medio de la Plataforma. </Body>
                        <Body><strong>“Tramo”</strong> tiene el significado que se le atribuye en el Artículo 4.1 a continuación. </Body>
                        <Body><strong>“Trayecto”</strong> se refiere a un trayecto en coche compartido. </Body>
                        <Body><strong>“Trayecto en coche compartido”</strong> significa el recorrido sujeto de un Anuncio de coche compartido publicado por un Conductor en la Plataforma por el cual el Conductor acepta compartir un Trayecto con Pasajeros, Usuarios de la plataforma, a cambio de la compartición de los gastos asociados al Trayecto. </Body>
                        <Body><strong>“Usuario”</strong> significa cualquier persona que haya creado una cuenta en la plataforma. </Body>
                    </Body>
                </PaddedContent>
                <ExtraLargeTitle>3. Registro en la aplicacion y creación de una cuenta</ExtraLargeTitle>
                <PaddedContent>
                    <LargeTitle>3.1. Condiciones de registro en la Plataforma</LargeTitle>
                    <PaddedContent>

                        <Body>
                            La Plataforma solamente puede ser utilizada por personas mayores de 18 años. Está estrictamente prohibido el registro en la plataforma por parte de un menor. Al acceder, utilizar o registrarse en la Plataforma, confirmas y garantizas que tienes más de 18 años.
                        </Body>
                    </PaddedContent>

                    <LargeTitle>3.2. Creación de una Cuenta</LargeTitle>
                    <PaddedContent>

                        <Body>
                            La Plataforma permite a los Usuarios publicar y ver Anuncios de coche compartido e interactuar entre ellos para reservar una Plaza. Es posible ver los Anuncios sin estar registrado en la Plataforma. No obstante, no podrás publicar un Anuncio de coche compartido ni reservar una Plaza sin haber creado primero una Cuenta para convertirte en Usuario.
                            Para crear tu Cuenta puedes utilizar cualquiera de los dos métodos utilizados a continuación:
                            (i)    rellenar todos los campos obligatorios en el formulario de registro;
                            (ii)    o iniciar sesión en tu cuenta de  Google a través de nuestra Plataforma (en adelante denominada como su “Cuenta de Google”). Al utilizar dicha funcionalidad, entiendes que ¿Vamos juntos? tendrá acceso a publicar en la Plataforma y conservar determinada información de tu Cuenta de Google. Puedes eliminar el enlace entre tu Cuenta de Google en cualquier momento haciendo la solicitud a ¿Vamos juntos?. Si deseas obtener más información sobre la utilización de tus datos obtenidos de tu Cuenta de Google, puedes leer nuestra Política de privacidad, la Política de privacidad de  Google.
                            Para registrarte en la Plataforma es necesario que hayas leído y aceptado las presentes CGU y la Política de privacidad.
                            Al crear tu Cuenta, independientemente del método seleccionado, te comprometes a proporcionar información verídica y precisa, y a actualizar dicha información por medio de tu perfil o enviando una notificación a ¿Vamos juntos?, con el objetivo de garantizar su relevancia y precisión durante la duración total de tu relación contractual con ¿Vamos juntos?.
                            En caso de que te registres utilizando un correo electrónico, te comprometes a guardar en secreto la contraseña seleccionada durante la creación de tu Cuenta y a no comunicársela a ninguna otra persona. En caso de pérdida o divulgación de tu contraseña, deberás comunicárselo a ¿Vamos juntos? inmediatamente. Tú eres el único responsable del uso de tu Cuenta por parte de terceras partes, salvo que hayas comunicado de forma expresa a ¿Vamos juntos? la pérdida, el uso fraudulento por parte de un tercero, o la revelación de tu contraseña a un tercero.
                            Recuerda no crear ni utilizar, bajo tu propia identidad o bajo la identidad de un tercero, ninguna Cuenta adicional a la creada inicialmente.
                        </Body>
                    </PaddedContent>
                    <LargeTitle>3.3. Verificación</LargeTitle>
                    <PaddedContent>
                        <Body>
                            ¿Vamos juntos? podrá, con fines de transparencia, de mejora de la veracidad o de prevención o detección de fraude, establecer un sistema para verificar parte de la información proporcionada en tu perfil. Se trata, fundamentalmente, de aquellos casos en los que introduces tu número de teléfono o nos proporcionas un Documento de Identidad.
                            Reconoces y aceptas que cualquier referencia en la Plataforma o en los Servicios a la información “certificada”, o a cualquier otro término similar, significa solamente que un Usuario ha superado con éxito el procedimiento de verificación existente en la Plataforma o en los Servicios para proporcionarle más información sobre el Usuario con el que está pensando compartir un Trayecto. ¿Vamos juntos? no puede garantizar la veracidad, fiabilidad o validez de la información sujeta al procedimiento de verificación.
                        </Body>
                    </PaddedContent>
                </PaddedContent>

                <ExtraLargeTitle>4. Utilización de los Servicios</ExtraLargeTitle>
                <PaddedContent>
                    <LargeTitle>4.1. Publicación de Anuncios</LargeTitle>
                    <PaddedContent>
                        <Body>
                            Como Usuario, siempre y cuando cumplas las condiciones establecidas a continuación, podrás crear y publicar Anuncios de coche compartido en la Plataforma introduciendo información sobre el Trayecto de coche compartido que vas a realizar(fechas / horas / puntos de recogida y llegada, número de plazas ofrecidas, opciones disponibles, cantidad de Costes compartidos, etc. ).
                            Solamente podrás publicar un Anuncio de coche compartido si cumples todas y cada una de las condiciones indicadas a continuación:
                            <br />
                            ●      (i)    posees un carnet de conducir válido y si corresponde, un carnet de salud para conducir;
                            <br />
                            ●      (ii)    publicas solamente Anuncios de coche compartido con vehículos de tu propiedad o utilizados con consentimiento expreso del propietario, y siempre y cuando estés autorizado para utilizar el vehículo con fines de desplazamiento compartido;
                            <br />
                            ●      (iii)    eres y seguirás siendo el conductor principal del vehículo sujeto del Anuncio de coche compartido;
                            <br />
                            ●      (iv)    el vehículo tiene un seguro a terceros o el que corresponda en vigor;
                            <br />
                            ●      (v)    no tienes ninguna contraindicación ni incapacidad médica para conducir;
                            <br />
                            ●      (vi)    el vehículo que vas a utilizar para el Trayecto es un turismo de 4 ruedas y un máximo de 7 asientos, excluyendo los conocidos como vehículos “sin carnet”.
                            <br />
                            ●      (vii)  no tienes intención de publicar otro anuncio para el mismo Trayecto de coche compartido en la Plataforma;
                            <br />
                            ●      (viii) no ofreces más Plazas de las disponibles en tu vehículo y de las permitidas en la Plataforma;
                            <br />
                            ●      (ix) todas las Plazas ofertadas tienen cinturón de seguridad, incluso en el caso de que el vehículo esté homologado con asientos sin cinturón de seguridad;
                            <br />
                            ●      (x) utilizas un vehículo en buen estado y que cumple con todas las normativas legales, y si corresponde, que el vehículo disponga del Certificado de Inspección Técnica Vehicular actualizado;
                            <br />
                            ●      (xi) eres un consumidor y no actúas como profesional.
                            <br />
                            Reconoces que tú eres el único responsable del contenido del Anuncio de coche compartido que publicas en la Plataforma. Por lo tanto, reconoces y garantizas la veracidad y precisión de toda la información incluida en tu Anuncio de coche compartido, y te comprometes a realizar el Trayecto de coche compartido bajo las condiciones descritas en tu Anuncio de coche compartido.
                            Tu Anuncio de coche compartido será publicado en la Plataforma y por lo tanto podrá ser visto por todos los Usuarios y visitantes, incluso por las personas que no son Usuarios de la Plataforma, que realicen una búsqueda en la Plataforma o en el sitio web de las empresas asociadas a ¿Vamos juntos?. ¿Vamos juntos? se reserva el derecho, a su exclusivo criterio y sin necesidad de previo aviso, a no publicar o a borrar, en cualquier momento, cualquier Anuncio de coche compartido que no cumpla con los presentes CGU o que considere que puede dañar su imagen, la imagen de la Plataforma o la imagen de los Servicios.
                            Se te informa de que en caso de que te presentes como consumidor utilizando la Plataforma cuando de hecho actúas como profesional, estás expuesto a las sanciones previstas por la ley aplicable.
                        </Body>
                    </PaddedContent>
                    <LargeTitle>4.2 Reservar una plaza</LargeTitle>
                    <PaddedContent>
                        <Body>
                            ¿Vamos juntos? ha establecido un sistema para la reserva de Plazas en línea (“Reserva”) para algunos de los Trayectos ofrecidos en la Plataforma.
                            Los métodos de reserva de un asiento dependen de la naturaleza del trayecto previsto.
                            ¿Vamos juntos? proporciona a los usuarios de la Plataforma un motor de búsqueda basado en diferentes criterios de búsqueda (lugar de origen, destino, fechas, etc.). Algunas funcionalidades adicionales se proporcionan en el motor de búsqueda cuando el usuario está conectado con su cuenta. ¿Vamos juntos? invita al usuario, independientemente del proceso de reserva utilizado, a consultar y utilizar cuidadosamente el buscador para determinar la oferta más adaptada a sus necesidades.
                        </Body>
                        <LargeTitle>
                            4.2.1. Trayecto en coche compartido
                        </LargeTitle>
                        <PaddedContent>
                            <Body>
                                Cuando un Pasajero esté interesado en un Anuncio, puede realizar una solicitud de reserva en línea. Esta solicitud puede o no ser aceptada por el Conductor.
                                En el momento de la Confirmación de reserva, ¿Vamos juntos? notificará al Pasajero, por lo que sera responsabilidad de ellos de coordinar los puntos de encuentro. A partir de este momento tú eres el único responsable del cumplimiento del contrato que te vincula con el otro Usuario.
                            </Body>
                        </PaddedContent>
                        <LargeTitle>4.2.2. Naturaleza designada de la reserva de Asiento</LargeTitle>
                        <PaddedContent>
                            <Body>
                                Cualquier utilización de los Servicios, tanto en calidad de Pasajero como de Conductor, está relacionada con un nombre específico. La identidad del Conductor y del Pasajero debe corresponderse con la identidad comunicada por los mismos a ¿Vamos juntos? y a los demás Usuarios que participan en el Trayecto de uso compartido.
                                La Plataforma está pensada para reservar Plazas para personas. Está prohibido reservar una Plaza para transportar cualquier objeto, paquete, animal que viaje solo o cualquier otro elemento material.
                                Está igualmente prohibido publicar un Anuncio para cualquier otro Conductor que no seas tú.
                            </Body>
                        </PaddedContent>
                    </PaddedContent>
                    <LargeTitle>4.3. Sistema de evaluación</LargeTitle>
                    <PaddedContent>
                        <LargeTitle>4.3.1. Funcionamiento</LargeTitle>
                        <PaddedContent>
                            <Body>
                                ¿Vamos juntos? te anima a publicar tus comentarios y / o valoraciones sobre un Conductor(en el caso de que seas un Pasajero) o sobre un Pasajero(en el caso de que seas un Conductor) con el cual hayas compartido un Trayecto o con quien se supone que hubieses debido compartir un Trayecto. Sin embargo, no está permitido dejar ningún comentario sobre otro Pasajero si eres un Pasajero, ni sobre ningún otro Usuario con quien no hayas viajado o con quien no tenías previsto viajar.
                                Tu opinión, al igual que las opiniones que hayan publicado otros Usuarios sobre ti, se veran refeljados en tu perfil y acumularan a tu Puntuación.
                            </Body>
                        </PaddedContent>

                        <LargeTitle>4.3.2. Moderación</LargeTitle>
                        <PaddedContent>
                            <Body>
                                Reconoces y aceptas que ¿Vamos juntos? se reserva el derecho a no publicar o a eliminar cualquier comentario, pregunta, opinión o respuesta a los mismos si considera que su contenido incumple lo establecido en las presentes CGU.
                            </Body>
                        </PaddedContent>
                        <LargeTitle>4.3.3. Límite</LargeTitle>
                        <PaddedContent>
                            <Body>
                                ¿Vamos juntos? se reserva el derecho a suspender tu Cuenta, limitar su acceso a los Servicios, o rescindir los presentes CGU en caso de que(i) hayas recibido al menos tres valoraciones negativas y(ii) las opiniones promedias que hayas recibido tengan una puntuación de 3 o inferior.
                            </Body>
                        </PaddedContent>
                    </PaddedContent>
                </PaddedContent>

                <ExtraLargeTitle>5. Condiciones financieras</ExtraLargeTitle>
                <PaddedContent>
                    <Body>
                        El acceso y el registro en la Plataforma, así como la búsqueda, visualización y publicación de Anuncios, no implican coste alguno.
                    </Body>

                    <LargeTitle>5.1. Costes compartidos y Precio</LargeTitle>
                    <PaddedContent>

                        <Body>
                            En el caso de un Viaje de coche compartido, los Costes compartidos los determinas tú, como Conductor, bajo tu única responsabilidad. Está estrictamente prohibido obtener ningún tipo de beneficio mediante el uso de nuestra Plataforma. Por lo tanto, estás de acuerdo en limitar la Cantidad de costes compartidos solicitadas a tus Pasajeros a los costes reales del Trayecto de coche compartido que vas a compartir con los Pasajeros. De lo contrario, asumirás los riesgos de recalificación de la transacción realizada a través de la Plataforma.
                            Cuando publiques un Anuncio de coche compartido, ¿Vamos juntos? te sugerirá una Cantidad de Costes compartidos, que tiene en cuenta principalmente la naturaleza del Trayecto y la distancia recorrida. Esta cantidad se indica solamente a modo de guía y puedes aumentarla o reducirla teniendo en cuenta los costes reales del Trayecto de coche de compartido. Para evitar el uso abusivo de la Plataforma, ¿Vamos juntos? limita la posibilidad de ajuste de los Costes compartidos.
                        </Body>
                    </PaddedContent>

                    <LargeTitle>5.2. Gastos de Gestión</LargeTitle>
                    <PaddedContent>
                        <Body>
                            ¿Vamos juntos?, a cambio del uso de la Plataforma, en el momento de la Reserva ni en ningún otro hasta la finalización del viaje compartido, cobrará  gastos de gestión(en adelante, los “Gastos de Gestión”). Ante cualquier modificación de los gastos de Gestión se informará debidamente al usuario mediante los datos de contacto registrados por el usuario.
                        </Body>
                    </PaddedContent>

                    <LargeTitle>5.3. Pago de la Cantidad de costes compartidos al Conductor</LargeTitle>
                    <PaddedContent>
                        <Body>
                            Tras el Trayecto de coche compartido, los Pasajeros pagarán directamente al conductor el coste publicado por este último.
                        </Body>
                    </PaddedContent>
                </PaddedContent>

                <ExtraLargeTitle>6. Objetivo no comercial y no empresarial de los Servicios y la Plataforma</ExtraLargeTitle>
                <PaddedContent>
                    <Body>
                        Aceptas utilizar los Servicios y la Plataforma solamente para ponerte en contacto, con fines no comerciales y no empresariales, con personas que desean compartir un Trayecto de coche compartido contigo.
                        En el contexto del Trayecto en coche compartido, reconociste que los derechos de los consumidores derivados de la legislación de Uruguay en materia de protección del consumidor no se aplican a su relación con otros Usuarios.
                        Como Conductor, aceptas no solicitar una Cantidad de costes compartidos superior a los costes reales del trayecto y que puedan generarte un beneficio, dejando especificado que, en el contexto de compartir un coste, tú tienes que asumir como Conductor tu propia parte de los costes incurridos en el Trayecto en coche compartido. Tú eres el único responsable de calcular los costes en los que incurres durante el Trayecto en coche compartido y de asegurarte de que la cantidad solicitada a tus Pasajeros no supera los costes reales del trayecto(excluyendo tu parte de los costes).
                        ¿Vamos juntos? se reserva el derecho a suspender tu Cuenta en caso de que utilices un vehículo de alquiler con conductor u otro vehículo comercial, taxi o vehículo de empresa con el objetivo de generar un beneficio a través de la Plataforma. Recuerda proporcionar a ¿Vamos juntos?, en caso de que te lo solicite, una copia del certificado de registro de tu vehículo y cualquier otra información que demuestre que estás autorizado a utilizar el vehículo en cuestión en la Plataforma y que no estás obteniendo beneficio alguno por el uso de la misma.
                        ¿Vamos juntos? también se reserva el derecho a suspender tu Cuenta, limitar tu acceso a los Servicios o resolver los presentes CGU en caso de que tu actividad en la Plataforma, dada la naturaleza de los Trayectos ofrecidos, tu frecuencia, el número de Pasajeros transportados y la Cantidad de costes compartidos solicitada, te conlleve a una posición en la que generes beneficio, o por cualquier otro motivo que sugiera a ¿Vamos juntos? que estás generando beneficio a través de la Plataforma.
                    </Body>
                </PaddedContent>

                <ExtraLargeTitle>7. Comportamiento de las personas que visitan la Plataforma y los Usuarios</ExtraLargeTitle>
                <PaddedContent>
                    <LargeTitle>7.1. Compromiso de todas las personas que visitan la Plataforma</LargeTitle>
                    <PaddedContent>
                        <Body>
                            Reconoces ser el único responsable de respetar todas las leyes, normativas y obligaciones aplicables a la utilización de la Plataforma.
                            Además, al utilizar la Plataforma y durante los Trayectos, te comprometes a:
                            <br />
                            ●      (i) no utilizar la Plataforma con fines profesionales, comerciales o con ánimo de lucro;
                            <br />
                            ●      (ii) no enviar a ¿Vamos juntos?(especialmente en el momento de crear o actualizar tu Cuenta) ni a los demás Usuarios de la Plataforma información falsa, confusa, maliciosa o fraudulenta;
                            <br />
                            ●      (iii) no hablar ni comportarse, ni publicar ningún contenido en la Plataforma que pueda resultar difamatorio, injurioso, obsceno, pornográfico, vulgar, ofensivo, agresivo, improcedente, violento, amenazante, acosador, de naturaleza racista o xenófoba, que tenga connotaciones sexuales, incite a la violencia, discriminación u odio, o que fomente actividades o el uso de substancias ilegales o de forma más general que sea contrario a los objetivos de la Plataforma y que pueda infringir los derechos de ¿Vamos juntos? o una tercera parte, o que pueda ser contrario a las buenas costumbres;
                            <br />
                            ●      (iv) no infringir los derechos y no dañar la imagen de ¿Vamos juntos?, en especial en lo que respecta a sus derechos de propiedad intelectual;
                            <br />
                            ●      (v) no abrir más de una Cuenta en la Plataforma y no abrir una Cuenta en nombre de un tercero;
                            <br />
                            ●      (vii) no ponerse en contacto con otro Usuario, por medio de la Plataforma, con otra finalidad que no sea la definición de las condiciones del desplazamiento compartido;
                            <br />
                            ●      (ix) cumplir con las presentes CGU y con la Política de privacidad.
                        </Body>
                    </PaddedContent>

                    <LargeTitle>7.2. Compromisos de los Conductores</LargeTitle>
                    <PaddedContent>

                        <Body>
                            Cuando utilizas la Plataforma como Conductor, te comprometes a:
                            <br />
                            ●      (i) respetar todas las leyes, normativas y códigos con respecto a la conducción y al vehículo, especialmente contar con un seguro de responsabilidad civil en vigor en el momento de realizar el Trayecto de coche compartido y estar en posesión de un carnet de conducir válido y de salud si corresponde;
                            <br />
                            ●      (ii) comprobar que tu póliza de seguro cubre los desplazamientos compartidos y que los Pasajeros con los que compartes el trayecto son considerados como terceros en tu vehículo y, por lo tanto, están cubiertos por tu seguro;
                            <br />
                            ●      (iii) no asumir ningún riesgo durante la conducción, ni tomar ningún producto que pueda afectar negativamente a tu atención y a tu capacidad de conducir de manera atenta y completamente segura;
                            <br />
                            ●      (iv) publicar Anuncios de coche compartido que se correspondan solamente con Trayectos ya programados;
                            <br />
                            ●      (v) realizar el Trayecto de coche compartido según la descripción en el Anuncio y a respetar las horas y lugares acordados con los demás Usuarios(en especial el punto de encuentro y el lugar de destino);
                            <br />
                            ●      (vi) no llevar a más Pasajeros que el número de Plazas indicados en el Anuncio de coche compartido;
                            <br />
                            ●      (vii) utilizar un vehículo en buen estado y que cumpla con todas las normativas legales, y si corresponde, con el Certificado de Inspeccion Técnica Vehicular, en vigor;
                            <br />
                            ●      (viii) a compartir con ¿Vamos juntos? o con cualquier Pasajero que lo solicite tu carnet de conducir, el certificado de registro de tu vehículo, tu póliza de seguro, el certificado de la Inspección Vehicular  y cualquier otro documento que demuestre tu capacidad para utilizar el vehículo como Conductor en la Plataforma;
                            <br />
                            ●      (ix) en caso de retraso o cambio en la hora o en el propio Trayecto, informar a tus Pasajeros y / o a Vitik sin demora;
                            <br />
                            ●      (x) en el caso de un Trayecto transfronterizo, mantener y tener disponible para el Pasajero y para cualquier autoridad que lo solicite, un documento válido que muestre tu identidad y tu derecho a cruzar la frontera;
                            <br />
                            ●      (xi) esperar a los Pasajeros en el punto de encuentro acordado al menos hasta 15 minutos después de la hora acordada;
                            <br />
                            ●      (xii) no publicar ningún Anuncio de coche compartido relativo a un vehículo que no sea tuyo o para el cual no tengas permiso para utilizarlo como transporte compartido;
                            <br />
                            ●      (xiii) asegurarte de que los Pasajeros pueden contactarte a través del chat interno de la plataforma en tu perfil
                            <br />
                            ●      (xiv) no generar ningún beneficio a través de la Plataforma;
                            <br />
                            ●      (xv) no tener ninguna contraindicación ni incapacidad médica para conducir;
                            <br />
                            ●      (xvi) comportarse de forma apropiada y responsable durante el Trayecto de coche compartido, de conformidad con la filosofía del desplazamiento compartido.
                            <br />
                            ●     (xvii) Solicitar a los pasajeros que identifiquen el equipaje con Nombre, Apellido y número de Documento vigente.
                        </Body>
                    </PaddedContent>

                    <LargeTitle>7.3. Compromisos de los Pasajeros</LargeTitle>

                    <PaddedContent>
                        <Body>
                            Para el trayecto de un coche compartido<br />
                            Cuando utilizas la Plataforma como Pasajero de un Trayecto de coche compartido, te comprometes a:
                            <br />
                            ●      (i) adoptar un comportamiento apropiado durante el Trayecto de coche compartido de modo que no interrumpa la concentración o la conducción del Conductor ni la paz y tranquilidad del resto de los Pasajeros;
                            <br />
                            ●      (ii) respetar el vehículo del Conductor y su limpieza;
                            <br />
                            ●      (iii) en caso de retraso, informar al Conductor y o a ¿Vamos juntos? sin demora;
                            <br />
                            ●      (iv) esperar al Conductor en el punto de encuentro acordado al menos hasta 15 minutos después de la hora acordada;
                            <br />
                            ●      (v) comunicar a ¿Vamos juntos?, o a cualquier Conductor que te lo solicite, tu documento de identidad o cualquier otro documento que pruebe tu identidad;
                            <br />
                            ●      (vi) no transportar durante un Trayecto de coche compartido ningún artículo, sustancia o animal que pueda distraer la concentración y conducción del Conductor, o cuya naturaleza, posesión o transporte sea ilegal;
                            <br />
                            ●      (vii) en el caso de un Trayecto de coche compartido transfronterizo, mantener y tener disponible para el Conductor y para cualquier autoridad que lo solicite, un documento válido que muestre tu identidad y tu derecho a cruzar la frontera;
                            <br />
                            ●      (viii) asegurarse de que los Conductores puedan contactarte a través del chat de la plataforma en tu perfil, incluso en el punto de reunión.
                            <br />
                            ●      (iv) Identificar su equipaje con Nombre, Apellido y número de Documento vigente.
                            <br />
                            ¿Vamos juntos? se reserva el derecho a suspender tu Cuenta, limitar tu acceso a los Servicios o rescindir las presentes CGU en caso de incumplimiento por parte del tercero en cuyo nombre has reservado la Plaza de conformidad con estas CGU
                        </Body>
                    </PaddedContent>
                </PaddedContent>


                <ExtraLargeTitle>8. Suspensión de cuentas, limitación de acceso y rescisión</ExtraLargeTitle>
                <PaddedContent>
                    <Body>
                        Puedes rescindir tus relaciones contractuales con ¿Vamos juntos?, en cualquier momento, de forma gratuita y sin tener que explicar el motivo. Para ello, simplemente debes notificarlo a ¿Vamos juntos?.
                        En el caso de (i) incumplimiento por tu parte de las presentes CGU, incluidos, entre otros el incumplimiento de tus obligaciones como Usuario según lo establecido en los Artículos 6 y 7 de arriba, (ii) superación del límite establecido arriba en el Artículo 4. 3. 3, o(iii) en el caso de que ¿Vamos juntos? tenga algún motivo real para creer que es necesario proteger su seguridad e integridad, la de los Usuarios o terceros, o para los fines de prevención de fraude o investigaciones, ¿Vamos juntos? se reserva el derecho a:
                        <br />
                        ●      (i)    rescindir las CGU que te vinculan con ¿Vamos juntos? de forma inmediata y sin previo aviso; y / o
                        <br />
                        ●      (ii)    evitar la publicación de, o eliminar cualquier opinión, Anuncio, mensaje, contenido, solicitud de Reserva o cualquier contenido que hayas publicado en la Plataforma; y / o
                        <br />
                        ●      (iii)    limitarte el acceso y el uso de la Plataforma; y / o
                        <br />
                        ●      (iv)    suspender de forma temporal o permanente tu Cuenta.
                        <br />
                        Cuando sea necesario realizar dicha rescisión, se te notificará sobre esta decisión para que puedas presentar tus explicaciones a ¿Vamos juntos?. ¿Vamos juntos? decidirá, a su discreción, si levantar o no la rescisión aplicada.
                    </Body>

                </PaddedContent>

                <ExtraLargeTitle>9. Datos de carácter personal</ExtraLargeTitle>
                <PaddedContent>
                    <Body>En el contexto del uso de la Plataforma por tu parte, ¿Vamos juntos? recopilará y procesará tu información personal. Al utilizar la Plataforma y registrarte como Usuario, reconoces y aceptas el procesamiento de tus datos personales por parte de ¿Vamos juntos? de conformidad con la legislación aplicable y lo establecido en la Política de privacidad. </Body>
                </PaddedContent>
                <ExtraLargeTitle>10. Propiedad intelectual</ExtraLargeTitle>
                <PaddedContent>
                    <LargeTitle>10.1. Contenido publicado por ¿Vamos juntos?</LargeTitle>
                    <PaddedContent>
                        <Body>
                            Con sujeción al contenido proporcionado por sus Usuarios, ¿Vamos juntos? es el titular único de todos los derechos de propiedad intelectual relacionados con el Servicio, la Plataforma, su contenido(en concreto los textos, imágenes, diseños, logotipos, vídeos, sonido, datos y gráficos) y con el software y las bases de datos que aseguran su funcionamiento.
                            ¿Vamos juntos? te garantiza un derecho personal, no exclusivo y no transferible para utilizar la Plataforma y los Servicios, para tu uso personal y privado, con un objetivo no comercial y de conformidad con la finalidad de la Plataforma y los Servicios.
                            Está totalmente prohibido utilizar o explotar la Plataforma y los Servicios, incluido su contenido, con cualquier otra finalidad que no sea la prevista sin previo consentimiento por escrito de ¿Vamos juntos?. En concreto, está totalmente prohibido lo siguiente:
                            <br />
                            ●      (i) reproducir, modificar, adaptar, distribuir, representar públicamente y diseminar la Plataforma, los Servicios y todo su contenido, salvo previa autorización expresa de ¿Vamos juntos?;
                            <br />
                            ●      (ii) descompilar y aplicar ingeniería inversa a la Plataforma o los Servicios, salvo las excepciones estipuladas por los textos aplicables;
                            <br />
                            ●      (iii) extraer o intentar extraer(en concreto utilizando robots de extracción de datos o herramientas similares de recolección de datos) una parte sustancial de los datos de la Plataforma.
                        </Body>
                    </PaddedContent>
                    <LargeTitle>10.2. Contenido publicado por el Usuario en la Plataforma</LargeTitle>
                    <PaddedContent>
                        <Body>
                            Con el objetivo de permitir la provisión de los Servicios, y en cumplimiento con el objetivo de la Plataforma, le otorgas a ¿Vamos juntos? una licencia no exclusiva para usar el contenido y los datos que has proporcionado en el contexto de la utilización de los Servicios(de aquí en adelante denominados como el “Contenido desarrollado por el Usuario”). Para poder permitir a ¿Vamos juntos? distribuir por medio de la red digital y de conformidad con cualquier protocolo de comunicación(en especial Internet y las redes móviles) y para poder ofrecer al público el contenido de la Plataforma, autorizas a ¿Vamos juntos? a reproducir, representar, adaptar y traducir tu Contenido desarrollado por el Usuario para todo el mundo y durante la duración completa de tus relaciones contractuales con ¿Vamos juntos?, de conformidad con lo siguiente:
                            <br />
                            ●      (i) autorizas a ¿Vamos juntos? a reproducir todo o parte de tu Contenido desarrollado por el Usuario en cualquier medio de grabación digital, conocido o por conocer, en concreto en cualquier servidor, disco duro, tarjeta de memoria u otro medio equivalente, en cualquier formato y por medio de cualquier proceso, ya conocido o por conocer, en el grado necesario para cualquier operación de almacenamiento, copia de seguridad, transmisión o descarga relacionada con el funcionamiento de la Plataforma y el suministro del Servicio;
                            <br />
                            ●      (ii) autorizas a ¿Vamos juntos? a adaptar y traducir su Contenido desarrollado por el Usuario, y a reproducir dichas adaptaciones en cualquier medio digital, conocido o por conocer, establecido en el punto(i) arriba, con el objetivo de suministrar los Servicios en diferentes idiomas. Este derecho incluye la opción de realizar modificaciones en el formato de tu Contenido desarrollado por el Usuario, con respecto a tu derecho moral, con el objetivo de respetar el diseño gráfico de la Plataforma o para hacer que tu Contenido sea compatible a nivel técnico con nuestro software para su posterior publicación por medio de la Plataforma.
                        </Body>
                    </PaddedContent>
                </PaddedContent>
                <ExtraLargeTitle>11. Función de ¿Vamos juntos?</ExtraLargeTitle>
                <PaddedContent>
                    <Body>
                        La Plataforma constituye una plataforma de movilidad de red en línea en la cual los Usuarios pueden crear y publicar Anuncios de coche de compartido de Trayectos de coche compartido con el objetivo de compartir los mismos y los costes asociados al Trayecto. Estos Anuncios de coche compartido serán visualizados por los demás Usuarios para comprobar las condiciones del Trayecto, y, cuando corresponda, para reservar directamente una Plaza en el vehículo en cuestión con el Usuario que ha publicado el Anuncio en la Plataforma.
                        Al utilizar la Plataforma y aceptar las presentes CGU, reconoces que ¿Vamos juntos? no es parte de ningún acuerdo formalizado entre tú y los demás Usuarios con el objetivo de compartir los costes relacionados con un Trayecto.
                        ¿Vamos juntos? no tiene ningún tipo de control sobre el comportamiento de sus Usuarios. No posee, explota, suministra ni administra los vehículos incluidos en los Anuncios, y no ofrece Trayectos en la Plataforma.
                        Reconoces y aceptas que ¿Vamos juntos? no controla la validez, veracidad o legalidad de los Anuncios, Plazas y Trayectos ofrecidos. En su capacidad de intermediario, ¿Vamos juntos? no ofrece servicio de transporte ni actúa en calidad de transportista, la función de ¿Vamos juntos? se limita a facilitar el acceso a la Plataforma.
                        En el contexto de Trayectos de coche compartido, los Usuarios(Conductores o Pasajeros) actúan bajo su única y total responsabilidad.
                        En su capacidad de intermediario, ¿Vamos juntos? no será considerado responsable de ningún incidente que tenga lugar durante un Trayecto, en especial en lo relacionado con:
                        <br />
                        ●      (i) información errónea comunicada por el Conductor  con respecto al Trayecto y sus términos;
                        <br />
                        ●      (ii) la cancelación o modificación de un Trayecto por parte de un Usuario
                        <br />
                        ●      (iii) el comportamiento de sus Usuarios durante, antes o después del Trayecto.

                    </Body>
                </PaddedContent>

                <ExtraLargeTitle>12. Funcionamiento, disponibilidad y funcionalidades de la Plataforma</ExtraLargeTitle>
                <PaddedContent>
                    <Body>
                        ¿Vamos juntos? intentará en la medida de lo posible mantener el funcionamiento constante de la Plataforma los 7 días de la semana y las 24 horas del día. Sin embargo, es posible que se pueda suspender de forma temporal el acceso a la Plataforma, sin previo aviso, por motivos técnicos, de mantenimiento, migración u operaciones de actualización, o debido a cortes de suministro o restricciones relacionadas con el funcionamiento de la red.
                        Además, ¿Vamos juntos? se reserva el derecho a modificar o suspender en su totalidad o en parte el acceso a la Plataforma o sus funcionalidades, a su discreción, bien sea de forma temporal o permanente.
                    </Body>
                </PaddedContent>

                <ExtraLargeTitle>13. Modificación de las condiciones generales de uso</ExtraLargeTitle>
                <PaddedContent>
                    <Body>
                        Estas CGU y los documentos integrados por referencia explícita forman el acuerdo completo entre tú y ¿Vamos juntos? con relación a la utilización de los Servicios. Cualquier otro documento mencionado en la Plataforma(Preguntas frecuentes, por ejemplo), tiene solamente fines indicativos.
                        ¿Vamos juntos? podrá modificar los presentes CGU para adaptarlos a su entorno tecnológico y comercial, y para cumplir con la legislación en vigor. Cualquier modificación realizada en los CGU será publicada en la Plataforma indicando la fecha de entrada en vigor, y recibirás una notificación de dicho cambio antes de la entrada en vigor del mismo.
                    </Body>
                </PaddedContent>
            </PaddedContent>
        </Container>
    )
}


const Container = styled.div`
    overflow-y: hidden;
`;

const Body = styled(UnPaddedBody)`
    padding-bottom: 8px;
`

const LargeTitle = styled(UnPaddedLargeTitle)`
    padding-bottom: 8px;
`

const PaddedContent = styled.div`
    padding-left: 24px;
    padding-bottom: 16px;
`


export default TermsAndConditions;

